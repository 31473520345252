export const itemList = [
  {
    iconName: "comment",
    itemUrl: "https://www.washingtonpost.com/entertainment/comics/",
    item: "Comics",
    iconVariant: "solid"
  },
  {
    iconName: "crossword",
    itemUrl: "https://www.washingtonpost.com/crossword-puzzles/",
    item: "Crosswords"
  },
  {
    iconName: "keyword",
    itemUrl: "https://www.washingtonpost.com/games/keyword/",
    item: "Keyword"
  },
  {
    iconName: "ontherecord",
    itemUrl: "https://www.washingtonpost.com/news-quiz/",
    item: "Quiz"
  },
  {
    iconName: "mahjongg",
    itemUrl: "http://games.washingtonpost.com/games/mahjongg-dark-dimensions/",
    item: "Mah Jongg"
  },
  {
    iconName: "sudoku",
    itemUrl: "https://washingtonpost.com/games/sudoku/",
    item: "Sudoku"
  },
  {
    iconName: "horoscopes",
    itemUrl: "https://www.washingtonpost.com/entertainment/horoscopes/",
    item: "Horoscopes"
  },
  {
    iconName: "solitaire",
    itemUrl: "http://games.washingtonpost.com/games/klondike-solitaire/",
    item: "Solitaire"
  }
];
