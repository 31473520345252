import React from "react";

import { DiversionsPropTypes } from "~/proptypes/diversions";

import GridItem from "~/components/layouts/utilities/grid-item.jsx";

import Item from "./item";
import { itemList } from "./item-list";

const Diversions = (props) => {
  const { customFields } = props;

  const linkWrapperClass = "inline-flex items-center black pr-lgmod pointer";

  return (
    <GridItem
      className="div-wrap"
      overrides={customFields}
      featureLabel="Diversions"
      placementRequirements={{ isFullSpanLayout: true }}
    >
      <div className="gray-darkest diversions">
        {itemList &&
          itemList.map((item) => {
            return (
              <div key={item.item}>
                <Item
                  key={item.item}
                  linkWrapperClass={linkWrapperClass}
                  item={item.item}
                  iconName={item.iconName}
                  itemUrl={item.itemUrl}
                />
              </div>
            );
          })}
      </div>
    </GridItem>
  );
};

Diversions.label = "Diversions";
Diversions.propTypes = DiversionsPropTypes;

export default Diversions;
